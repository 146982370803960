import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import type { WindowLocation } from '@reach/router';
import clsx from 'clsx';

import Layout from '../components/Layout';
import Card from '../components/shared/Card';
import { Category, ImageWithAlt, Position, Slug } from '../types';
import { mapEdgesToNodes } from '../utils';
import SEO from '../components/SEO';

interface Props {
  location: WindowLocation;
  data: {
    allSanityProject: {
      edges: Array<{
        node: {
          _id: string;
          slug: Slug;
          image: ImageWithAlt;
          title: string;
          category: Category;
        };
      }>;
    };
  };
}

interface TagProps {
  active: boolean;
  label: string;
  value: string;
}

const filters = [
  {
    value: 'svi',
    label: 'Svi projekti',
  },
  {
    value: 'visokogradnja',
    label: 'Visokogradnja',
  },
  {
    value: 'niskogradnja',
    label: 'Niskogradnja',
  },
  {
    value: 'hidrogradnja',
    label: 'Hidrogradnja',
  },
];

const Tag: React.FC<TagProps> = ({ active, value, label }) => (
  <Link
    className={clsx([
      'text-md inline-block cursor-pointer rounded-lg px-4 py-1.5 font-semibold hover:text-blue-500 focus:outline-none focus:ring-2',
      active ? 'bg-blue-50 text-blue-600' : 'text-gray-500',
    ])}
    to={`?filter=${value}`}
  >
    {label}
  </Link>
);

function filterProjects(allSanityProject, selectedFilter) {
  const projects = mapEdgesToNodes(allSanityProject);

  if (selectedFilter === 'svi') {
    return projects;
  }

  return projects.filter(
    (project) => project.category.name.toLowerCase() === selectedFilter,
  );
}

const Projects: React.FC<Props> = ({
  location,
  data: { allSanityProject },
}) => {
  // using state instead of variables because of implications with initial filter state
  // at build time where it always selects "Svi projekti" even when URL contains different one
  const [selectedFilter, setSelectedFilter] = useState('svi');
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setSelectedFilter(searchParams.get('filter') || 'svi');
  }, [location.search]);

  useEffect(() => {
    setFilteredProjects(filterProjects(allSanityProject, selectedFilter));
  }, [selectedFilter]);

  return (
    <Layout>
      <SEO title="Referenc lista" />

      <h1 className="sr-only">Keso Gradnja projekti</h1>

      <div className="container mt-4 space-y-2 sm:mt-8 sm:space-x-1 sm:space-y-0">
        {filters.map((filter) => (
          <Tag
            active={selectedFilter === filter.value}
            key={filter.value}
            label={filter.label}
            value={filter.value}
          />
        ))}
      </div>

      <main className="container mt-12">
        <div className="grid gap-x-5 gap-y-10 sm:grid-cols-2 xl:grid-cols-3">
          {filteredProjects.map((node) => (
            <Card
              href={node.slug.current}
              image={node.image}
              key={node._id}
              position={Position.landscape}
              title={<h2>{node.title}</h2>}
              type={node.category.name}
            />
          ))}
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query AllProjects {
    allSanityProject(sort: { fields: metadata___startDate, order: DESC }) {
      edges {
        node {
          _id
          title
          slug {
            current
          }
          category {
            name
          }
          image {
            alt
            asset {
              _id
              fluid {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Projects;
